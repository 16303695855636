<template>
  <v-container fluid>
    <ShopTabs/>
    <v-row>
      <v-col cols="9">
        <h3 class="font-semibold text-blue mb-3">
          Events Happening Now
        </h3>
        <v-row>
          <v-col v-for="event in events" :key="event.id" lg="4" md="6" sm="12">
            <EventCard :event="event"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopTabs from "@/views/Shop/ShopTabs.vue";
import EventCard from "@/components/Shop/Events/EventCard.vue";

export default {
  name: "ShopEvents",
  components: {EventCard, ShopTabs},
  mounted() {
    this.getAllEvents();
  },
  watch: {
    reloadShopModule(val){
      if(val && val === 'event'){
        this.getAllEvents();  
      }
    }
  },  
  computed: {
    reloadShopModule(){
      return this.$store.getters.getReloadShopModule;
    },
    getCurrentVenueId(){
      return this.$store.getters.svid;
    }
  },
  methods: {
    getAllEvents() {
      this.events = [];
      const venueId = this.getCurrentVenueId;
      this.showLoader('Loading Events');
      this.$http
          .get(`venues/shop/events?venueId=${venueId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.events = response.data.data;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader();
          });
    }
  },
  data() {
    return {
      events: []
    }
  }
}
</script>

<style scoped>

</style>